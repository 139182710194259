<template>
  <div class="total">
    <h4 class="total__title">{{ $t('system.total') }}</h4>
    <div class="total__item">
      <div>{{ $t('system.services') }}</div>
      <div>${{ total.services.toFixed(2) }}</div>
    </div>
    <div class="total__item">
      <div>{{ $t('system.drive_and_labor_time') }}</div>
      <div>${{ total.driveAndLaborTimes.toFixed(2) }}</div>
    </div>
    <div class="total__item">
      <div>{{ $t('system.expenses') }}</div>
      <div>${{ total.expenses.toFixed(2) }}</div>
    </div>
    <div class="total__item">
      <div>{{ $t('system.total_due') }}</div>
      <div>${{ total.sum.toFixed(2) }}</div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: 'TotalInfo',
  props: {
    workOrderId: {
      type: [String, Number],
      required: true,
    },
  },
  computed: {
    ...mapGetters('workOrderPayments', ['totalPayment']),
    total() {
      const sum = Object.values(this.totalPayment(this.workOrderId)).reduce((sum, currentValue) => sum + currentValue, 0);
      return {
        ...this.totalPayment(this.workOrderId),
        sum,
      };
    },
  },
};
</script>

<style
    lang="scss"
    scoped
>
.total {
  width: 100%;
  padding: 10px 15px;
  border-radius: 10px;
  background: #eef2fb;

  &__title {
    color: #676767;
  }

  &__item {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #dde0e7;

    div {
      padding: 5px 0;

      &:last-child {
        margin-left: 10px;
      }
    }

    &:last-child {
      font-weight: bold;
      font-size: 1.1em;
      padding: 4px 0 0;
      border-bottom: none;
    }
  }
}
</style>
